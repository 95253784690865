import React from 'react';

export default function LinkedInIcon(props: React.SVGAttributes<{}>) {
    return (
        <svg width="36" height="38" viewBox="0 0 36 38" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M30.6736 31.7732H25.3395V23.3544C25.3395 21.3469 25.3039 18.7626 22.5653 18.7626C19.7871 18.7626 19.362 20.9499 19.362 23.2083V31.7726H14.0281V14.4603H19.1486V16.8262H19.2204C19.7328 15.9431 20.4734 15.2167 21.3631 14.7242C22.2528 14.2318 23.2584 13.9917 24.2727 14.0296C29.6791 14.0296 30.6758 17.6135 30.6758 22.2759L30.6736 31.7732ZM8.00932 12.0939C6.29974 12.0942 4.9136 10.6976 4.91332 8.9747C4.91303 7.25177 6.29861 5.8548 8.00819 5.85451C9.71777 5.85409 11.1039 7.25063 11.1042 8.97357C11.1043 9.80096 10.7784 10.5945 10.198 11.1797C9.61757 11.7649 8.83029 12.0937 8.00932 12.0939ZM10.6764 31.7734H5.33674V14.4603H10.6763L10.6764 31.7734ZM33.3328 0.862016H2.65642C1.20658 0.845576 0.0175967 2.01607 -0.00012207 3.47724V34.5222C0.0170342 35.9841 1.20588 37.1557 2.65628 37.1404H33.3328C34.7863 37.1586 35.9798 35.9869 35.9999 34.5222V3.47483C35.9792 2.01083 34.7856 0.840474 33.3328 0.859606"
                fill="#0A66C2"
            />
        </svg>
    );
}
