import { combineReducers, configureStore, Reducer } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { persistStore } from 'redux-persist';

import environments from '@app/configs/environments';
import { RESET_STATE_ACTION_TYPE } from '@app/store/actions/resetState';
import { formUrlApi } from './formUrl/api';
import { authApi } from '../store/users/api';
import { analyticsApi } from '@app/store/analytics/api';
import searchReducer from '@app/store/formUrl/searchSlice';
import timeRangeReducer from '@app/store/analytics/timeRangeSlice';

const middlewares = [formUrlApi.middleware, authApi.middleware, analyticsApi.middleware];

const reducers = {
    [formUrlApi.reducerPath]: formUrlApi.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [analyticsApi.reducerPath]: analyticsApi.reducer,
    search: searchReducer,
    timeRange: timeRangeReducer
};

const combinedReducer = combineReducers<typeof reducers>(reducers);

export const rootReducer: Reducer<RootState> = (state, action) => {
    if (action.type === RESET_STATE_ACTION_TYPE) {
        state = {} as RootState;
    }
    return combinedReducer(state, action);
};

export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false
        }).concat(middlewares),
    devTools: !environments.IS_IN_PRODUCTION_MODE
});

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof combinedReducer>;

setupListeners(store.dispatch);
