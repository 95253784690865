import React from 'react';

export default function WhatsAppIcon(props: React.SVGAttributes<{}>) {
    return (
        <svg width="36" height="38" viewBox="0 0 36 38" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M0.768135 18.7828C0.767291 21.8311 1.56379 24.8076 3.07832 27.4311L0.623291 36.3948L9.79654 33.9896C12.3338 35.3708 15.1765 36.0945 18.0653 36.0947H18.0729C27.6094 36.0947 35.3723 28.3346 35.3764 18.7964C35.3782 14.1745 33.5799 9.82835 30.3126 6.55854C27.0459 3.28901 22.7013 1.48746 18.0722 1.48535C8.53458 1.48535 0.772072 9.24504 0.768135 18.7828Z"
                fill="url(#paint0_linear_849_672)"
            />
            <path
                d="M0.150469 18.7773C0.149484 21.9353 0.974531 25.0182 2.54306 27.7356L0 37.0207L9.50217 34.5292C12.1203 35.9567 15.0681 36.7093 18.0676 36.7105H18.0754C27.954 36.7105 35.9958 28.6712 36 18.7916C36.0017 14.0036 34.1387 9.5012 30.7547 6.11425C27.3703 2.72772 22.8703 0.861344 18.0754 0.859375C8.19506 0.859375 0.154406 8.8975 0.150469 18.7773ZM5.80936 27.2676L5.45456 26.7044C3.96309 24.3329 3.17587 21.5924 3.177 18.7784C3.18009 10.5666 9.86316 3.88563 18.081 3.88563C22.0607 3.88731 25.8007 5.43869 28.6138 8.25344C31.4267 11.0685 32.9746 14.8105 32.9736 18.7905C32.97 27.0023 26.2867 33.6841 18.0754 33.6841H18.0695C15.3958 33.6827 12.7735 32.9646 10.4867 31.6077L9.94247 31.285L4.30369 32.7634L5.80936 27.2676Z"
                fill="url(#paint1_linear_849_672)"
            />
            <path
                d="M13.5954 11.2862C13.2598 10.5405 12.9067 10.5255 12.5877 10.5124C12.3264 10.5011 12.0277 10.502 11.7293 10.502C11.4306 10.502 10.9453 10.6143 10.5351 11.0622C10.1245 11.5105 8.96741 12.5939 8.96741 14.7974C8.96741 17.0008 10.5724 19.1304 10.7961 19.4295C11.0201 19.7281 13.8945 24.3946 18.4468 26.1898C22.2302 27.6817 23.0001 27.385 23.8212 27.3102C24.6425 27.2356 26.4711 26.2271 26.8442 25.1812C27.2176 24.1356 27.2176 23.2392 27.1056 23.0519C26.9937 22.8653 26.695 22.7532 26.2471 22.5293C25.7992 22.3055 23.5972 21.2218 23.1867 21.0723C22.7761 20.923 22.4775 20.8485 22.1788 21.2969C21.8802 21.7447 21.0225 22.7532 20.7611 23.0519C20.4999 23.3513 20.2385 23.3886 19.7907 23.1645C19.3426 22.9398 17.9002 22.4675 16.1889 20.9418C14.8575 19.7547 13.9586 18.2887 13.6973 17.8402C13.436 17.3925 13.6693 17.1497 13.8939 16.9266C14.0952 16.7259 14.342 16.4036 14.5661 16.1422C14.7894 15.8806 14.864 15.694 15.0133 15.3953C15.1628 15.0963 15.088 14.8348 14.9762 14.6107C14.864 14.3867 13.9936 12.1717 13.5954 11.2862Z"
                fill="url(#paint2_linear_849_672)"
            />
            <defs>
                <linearGradient id="paint0_linear_849_672" x1="1738.28" y1="3492.43" x2="1738.28" y2="1.48535" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#F9F9F9" />
                    <stop offset="1" stopColor="white" />
                </linearGradient>
                <linearGradient id="paint1_linear_849_672" x1="1800" y1="3616.99" x2="1800" y2="0.859375" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#1FAF38" />
                    <stop offset="1" stopColor="#60D669" />
                </linearGradient>
                <linearGradient id="paint2_linear_849_672" x1="919.139" y1="1698.76" x2="919.139" y2="10.502" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#1FAF38" />
                    <stop offset="1" stopColor="#60D669" />
                </linearGradient>
            </defs>
        </svg>
    );
}
