import { createApi } from '@reduxjs/toolkit/query/react';
import { customBaseQueryWithReauth } from '@app/store/customebasequery';
import { FormMetric, FormPageviews, FormStats } from '@app/modals/analytics/analyticsDTO';
import { GetMetricsQueryParams, GetPageViewsQueryParams, GetStatsQueryParams } from '@app/modals/analytics/metrics';

export const ANALYTICS_REDUCER_PATH = 'analytics';

export const analyticsApi = createApi({
    reducerPath: ANALYTICS_REDUCER_PATH,
    baseQuery: customBaseQueryWithReauth,
    endpoints: (builder) => ({
        getFormStats: builder.query<FormStats, GetStatsQueryParams>({
            query: ({ startAt, endAt, mappedDomain, mappedPath, ...params }) => ({
                url: '/analytics/stats',
                method: 'GET',
                params: { startAt, endAt, mappedDomain, mappedPath, ...params }
            })
        }),
        getFormPageViews: builder.query<FormPageviews, GetPageViewsQueryParams>({
            query: ({ startAt, endAt, mappedDomain, mappedPath, unit, timezone, ...params }) => ({
                url: '/analytics/page-views',
                method: 'GET',
                params: { startAt, endAt, mappedDomain, mappedPath, unit, timezone, ...params }
            })
        }),
        getFormMetrics: builder.query<FormMetric, GetMetricsQueryParams>({
            query: ({ startAt, endAt, mappedDomain, mappedPath, type, limit = 500, ...params }) => ({
                url: '/analytics/metrics',
                method: 'GET',
                params: { startAt, endAt, mappedDomain, mappedPath, type, limit, ...params }
            })
        })
    })
});

export const { useGetFormStatsQuery, useGetFormPageViewsQuery, useGetFormMetricsQuery } = analyticsApi;
