import { createApi } from '@reduxjs/toolkit/query/react';
import { customBaseQueryWithReauth } from '../customebasequery';

export interface UserStatus {
    id: string;
    email: string;
    first_name: string;
    last_name: string;
    profile_image?: string;
    isLoading?: boolean;
}

export const authApi = createApi({
    tagTypes: ['logout'],

    reducerPath: 'authApi',
    baseQuery: customBaseQueryWithReauth,
    keepUnusedDataFor: 0,
    endpoints: (builder) => ({
        googleLogin: builder.query<any, void>({
            query: () => ({
                url: '/auth/google/oauth',
                method: 'GET'
            })
        }),

        googleCallback: builder.query<any, any>({
            query: (params) => ({
                url: '/auth/google/callback',
                method: 'GET',
                params: {...params}
            })
        }),

        sendOtp: builder.mutation<any, { email: string }>({
            query: (body) => ({
                url: '/auth/otp/send',
                method: 'POST',
                body
            })
        }),

        verifyOtp: builder.mutation<any, { otp_token: string; email: string }>({
            query: (loginDetails) => ({
                url: '/auth/otp/verify',
                method: 'POST',
                body: loginDetails
            })
        }),
        getUserStatus: builder.query<UserStatus, void>({
            query: () => '/auth/status',
            providesTags: ['logout']
        }),

        logout: builder.mutation<void, void>({
            query: () => ({
                url: '/auth/logout',
                method: 'GET'
            }),
            invalidatesTags: ['logout'],
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled;
                    dispatch(authApi.util.resetApiState());
                } catch (error) {
                    console.error('Logout failed', error);
                }
            }
        })
    })
});

export const { useGoogleLoginQuery, useLazyGoogleCallbackQuery, useSendOtpMutation, useVerifyOtpMutation, useGetUserStatusQuery, useLogoutMutation } = authApi;

export default {
    authApi: authApi.reducer
};
