import { useModal } from '@app/components/modal-views/context';
import { useRouter } from 'next/router';
import { useLogoutMutation } from '@app/store/users/api';

export default function LogoutModal() {
    const { closeModal } = useModal();
    const router = useRouter();
    const [logout] = useLogoutMutation();
    const handleLogout = async () => {
        await logout().unwrap();
        router.push('/');
    };

    return (
        <div className="flex w-full max-w-[421px] flex-col gap-8 rounded-lg bg-white p-10">
            <div className="flex flex-col gap-2">
                <h1 className="text-xl font-semibold">Logout?</h1>
                <p className="text-sm text-gray-600">Are you sure you want to log out? </p>
            </div>
            <div className="flex w-full max-w-[450px] justify-between gap-4">
                <button onClick={closeModal} className="flex h-[44px] w-[164.5px] items-center justify-center rounded-[8px] border border-neutral-400 px-6 py-3 font-semibold text-gray-700 hover:bg-gray-100 focus-visible:outline-none">
                    Cancel
                </button>

                <button className="flex h-[44px] w-[164.5px] items-center justify-center rounded-[8px] bg-red-600 px-6 py-3 font-semibold text-white hover:bg-red-700" onClick={handleLogout}>
                    Logout
                </button>
            </div>
        </div>
    );
}
