import React from 'react';

export default function FacebookIcon(props: React.SVGAttributes<{}>) {
    return (
        <svg width="36" height="38" viewBox="0 0 36 38" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M36 19C36 8.98127 27.9411 0.859375 18 0.859375C8.05894 0.859375 0 8.98127 0 19C0 28.0544 6.58237 35.5594 15.1875 36.9202V24.2438H10.6172V19H15.1875V15.0034C15.1875 10.4569 17.8748 7.94556 21.9864 7.94556C23.9558 7.94556 26.0156 8.29987 26.0156 8.29987V12.7642H23.7459C21.5099 12.7642 20.8125 14.1625 20.8125 15.5972V19H25.8047L25.0066 24.2438H20.8125V36.9202C29.4176 35.5594 36 28.0546 36 19Z"
                fill="#1877F2"
            />
            <path
                d="M25.0066 24.2435L25.8047 18.9998H20.8125V15.597C20.8125 14.1622 21.5099 12.7639 23.7459 12.7639H26.0156V8.29962C26.0156 8.29962 23.9558 7.94531 21.9863 7.94531C17.8748 7.94531 15.1875 10.4567 15.1875 15.0031V18.9998H10.6172V24.2435H15.1875V36.92C16.1179 37.0669 17.0582 37.1406 18 37.1404C18.9418 37.1406 19.8821 37.0669 20.8125 36.92V24.2435H25.0066Z"
                fill="white"
            />
        </svg>
    );
}
