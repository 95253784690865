import { useModal } from '@app/components/modal-views/context';
import { useDeleteFormMutation } from '@app/store/formUrl/api';
import { toast } from 'react-toastify';
import { useRouter } from 'next/router';

export default function DeleteFormModal({ form_id }: { form_id: string }) {
    const { closeModal } = useModal();
    const router = useRouter();
    const [deleteFormLink] = useDeleteFormMutation();
    const handleDeleteClick = async () => {
        await deleteFormLink(form_id);
        closeModal();
        router.push('/dashboard');
        toast.success(`Form Deleted successfully.`);
    };

    return (
        <div className="flex w-full max-w-[421px] flex-col gap-8 rounded-lg bg-white p-10">
            <div className="flex flex-col gap-2">
                <h1 className="text-xl font-semibold">Delete Link?</h1>
                <p className="text-sm text-gray-600">Are you sure you want to delete this link? This action cannot be undone.</p>
            </div>
            <div className="flex w-full max-w-[450px] justify-between gap-4">
                <button onClick={closeModal} className="flex h-[44px] w-[164.5px] items-center justify-center rounded-[8px] border border-neutral-400 px-6 py-3 font-semibold text-gray-700 hover:bg-gray-100">
                    Cancel
                </button>
                <button className="flex h-[44px] w-[164.5px] items-center justify-center rounded-[8px] bg-red-600 px-6 py-3 font-semibold text-white hover:bg-red-700" onClick={handleDeleteClick}>
                    Delete
                </button>
            </div>
        </div>
    );
}
