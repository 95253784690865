import environments from '@app/configs/environments';
import type { BaseQueryFn, FetchArgs, FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { fetchBaseQuery } from '@reduxjs/toolkit/query';
import { Mutex } from 'async-mutex';

// Create a new mutex
const mutex = new Mutex();

const baseQuery = fetchBaseQuery({
    baseUrl: environments.API_ENDPOINT_HOST,
    mode: 'cors',
    prepareHeaders: (headers) => {
        headers.append('Content-Type', 'application/json');
        return headers;
    },
    credentials: 'include'
});

// Interceptor to handle refresh token
export const customBaseQueryWithReauth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (args, api, extraOptions) => {
    // Wait until the mutex is available without locking it
    await mutex.waitForUnlock();
    let result = await baseQuery(args, api, extraOptions);

    if (result.error && result.error.status === 401) {
        // Checking whether the mutex is locked
        if (!mutex.isLocked()) {
            const release = await mutex.acquire();
            try {
                // Using '/api/auth/refresh' to include the API base path
                const refreshResult = await fetch(environments.API_ENDPOINT_HOST + '/auth/refresh', {
                    method: 'POST',
                    mode: 'cors',
                    credentials: 'include'
                });

                if (refreshResult.status === 200) {
                    // Retry the initial query after successful token refresh
                    result = await baseQuery(args, api, extraOptions);
                } else {
                    if (!(window.location.href.includes('/login') || window.location.href.includes('/auth/oauth') || window.location.pathname == '/')) {
                        // Redirect to login page
                        window.location.href = '/login';
                    }
                    // Provide a meaningful error message
                    return {
                        error: {
                            status: 401,
                            statusText: 'Unauthorized',
                            data: 'Token refresh failed. Please log in again.'
                        } as FetchBaseQueryError
                    };
                }
            } finally {
                // Release the mutex
                release();
            }
        } else {
            // Wait until the mutex is available without locking it
            await mutex.waitForUnlock();
            result = await baseQuery(args, api, extraOptions);
        }
    }

    return result;
};
