import * as React from 'react';
import cn from 'classnames';

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {}

export default function TextInput({ className, type, ...props }: InputProps) {
    return (
        <input
            type={type}
            className={cn(
                'body1-responsive flex h-14 w-full border-input bg-white-1 px-5 py-6 placeholder:text-neutral-600 focus-visible:border-none focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-secondary-600 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 sm:h-[70px]',
                className
            )}
            {...props}
        />
    );
}
