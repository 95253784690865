import { Close } from '@app/components/icons/close';
import { FacebookMessengerShareButton, FacebookShareButton, LinkedinShareButton, TelegramShareButton, TwitterShareButton, WhatsappShareButton } from 'react-share';
import { useModal } from '@app/components/modal-views/context';
import MessengerIcon from '@app/components/icons/share/messenger-icon';
import WhatsAppIcon from '@app/components/icons/share/whatsApp-icon';
import FacebookIcon from '@app/components/icons/share/facebook-icon';
import LinkedInIcon from '@app/components/icons/share/linkedIn-icon';
import TwitterIcon from '@app/components/icons/share/twitter-icon';
import TelegramIcon from '@app/components/icons/share/telegram-icon';
import { toast } from 'react-toastify';
import { ToastId } from '@app/constants/toast-id';

export default function ShareModal({ customizeUrl }: { customizeUrl: string }) {
    const { closeModal } = useModal();
    const handleCopyCustomizeLink = () => {
        navigator.clipboard.writeText(customizeUrl);
        toast.success('Copied to clipboard', { toastId: ToastId.SUCCESS_TOAST });
    };
    return (
        <div className="flex w-full max-w-[728px] flex-col gap-6 rounded-lg bg-white p-6 sm:p-10">
            <div className="flex items-center justify-between">
                <h2 className="text-base font-semibold text-gray-800 sm:text-lg">Share your customized link</h2>
                <div onClick={closeModal} className="hover:cursor-pointer">
                    <Close />
                </div>
            </div>

            <div className="flex h-14 max-w-full items-center justify-between overflow-hidden rounded-lg border">
                <p className="px-2 py-2 text-sm text-gray-700 focus:outline-none sm:px-3 sm:text-base">{customizeUrl}</p>
                <button className="mr-2 rounded-lg bg-gray-100 px-3 py-2 text-sm hover:bg-blue-100 sm:mr-4 sm:px-4 sm:text-base" onClick={handleCopyCustomizeLink}>
                    Copy link
                </button>
            </div>

            {/* Social Icons */}
            <div className="mt-4 grid max-w-full grid-cols-3 space-x-2 sm:mt-6 sm:grid-cols-6 sm:space-x-4">
                <div className="mb-2 flex min-w-[80px] flex-col items-center sm:min-w-0">
                    <div className="flex h-16 w-16 items-center justify-center rounded-md border sm:h-20 sm:w-20">
                        <WhatsappShareButton url={customizeUrl}>
                            <WhatsAppIcon />
                        </WhatsappShareButton>
                    </div>
                    <span className="mt-2 text-xs text-gray-800 sm:text-sm">WhatsApp</span>
                </div>
                <div className="flex min-w-[80px] flex-col items-center sm:min-w-0">
                    <div className="flex h-16 w-16 items-center justify-center rounded-md border sm:h-20 sm:w-20">
                        <FacebookShareButton url={customizeUrl}>
                            <FacebookIcon />
                        </FacebookShareButton>
                    </div>
                    <span className="mt-2 text-xs text-gray-800 sm:text-sm">Facebook</span>
                </div>
                <div className="flex min-w-[80px] flex-col items-center sm:min-w-0">
                    <div className="flex h-16 w-16 items-center justify-center rounded-md border sm:h-20 sm:w-20">
                        <FacebookMessengerShareButton url={customizeUrl} appId="ssasa">
                            <MessengerIcon />
                        </FacebookMessengerShareButton>
                    </div>
                    <span className="mt-2 text-xs text-gray-800 sm:text-sm">Messenger</span>
                </div>
                <div className="flex min-w-[80px] flex-col items-center sm:min-w-0">
                    <div className="flex h-16 w-16 items-center justify-center rounded-md border sm:h-20 sm:w-20">
                        <LinkedinShareButton url={customizeUrl}>
                            <LinkedInIcon />
                        </LinkedinShareButton>
                    </div>
                    <span className="mt-2 text-xs text-gray-800 sm:text-sm">LinkedIn</span>
                </div>
                <div className="flex min-w-[80px] flex-col items-center sm:min-w-0">
                    <div className="flex h-16 w-16 items-center justify-center rounded-md border sm:h-20 sm:w-20">
                        <TwitterShareButton url={customizeUrl}>
                            <TwitterIcon />
                        </TwitterShareButton>
                    </div>
                    <span className="mt-2 text-xs text-gray-800 sm:text-sm">Twitter</span>
                </div>
                <div className="flex min-w-[80px] flex-col items-center sm:min-w-0">
                    <div className="flex h-16 w-16 items-center justify-center rounded-md border sm:h-20 sm:w-20">
                        <TelegramShareButton url={customizeUrl}>
                            <TelegramIcon />
                        </TelegramShareButton>
                    </div>
                    <span className="mt-2 text-xs text-gray-800 sm:text-sm">Telegram</span>
                </div>
            </div>
        </div>
    );
}
