import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface SearchState {
    queryTag: string;
}

const initialState: SearchState = {
    queryTag: ''
};
const searchSlice = createSlice({
    // reducerPath: 'SEARCH',
    name: 'search',
    initialState,
    reducers: {
        setQueryTag: (state, action: PayloadAction<string>) => {
            state.queryTag = action.payload;
        }
    }
});
export const { setQueryTag } = searchSlice.actions;
export default searchSlice.reducer;
