import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface TimeRangeState {
    startAt: number;
    endAt: number;
    unit: string;
    range: string;
}

const initialState: TimeRangeState = {
    startAt: Date.now() - 24 * 60 * 60 * 1000,
    endAt: Date.now(),
    unit: 'hour',
    range: 'Last 24 hours'
};

const timeRangeSlice = createSlice({
    name: 'timeRange',
    initialState,
    reducers: {
        setTimeRange: (state, action: PayloadAction<TimeRangeState>) => {
            state.startAt = action.payload.startAt;
            state.endAt = action.payload.endAt;
            state.unit = action.payload.unit;
            state.range = action.payload.range;
        }
    }
});
export const { setTimeRange } = timeRangeSlice.actions;
export default timeRangeSlice.reducer;
