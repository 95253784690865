import React from 'react';

export default function TelegramIcon(props: React.SVGAttributes<{}>) {
    return (
        <svg width="36" height="38" viewBox="0 0 36 38" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M18 0.859375C13.2272 0.859375 8.64563 2.77179 5.27344 6.17259C1.8978 9.57476 0.000965949 14.1887 0 19C0 23.8092 1.89844 28.4266 5.27344 31.8274C8.64563 35.2282 13.2272 37.1406 18 37.1406C22.7728 37.1406 27.3544 35.2282 30.7266 31.8274C34.1016 28.4266 36 23.8092 36 19C36 14.1908 34.1016 9.57339 30.7266 6.17259C27.3544 2.77179 22.7728 0.859375 18 0.859375Z"
                fill="url(#paint0_linear_849_942)"
            />
            <path
                d="M8.14777 18.8079C13.3959 16.504 16.8946 14.9851 18.644 14.2512C23.6446 12.1557 24.6825 11.7917 25.3603 11.7794C25.5093 11.777 25.8412 11.8141 26.0578 11.9906C26.2378 12.1394 26.2884 12.3406 26.3137 12.4819C26.3362 12.6231 26.3671 12.9448 26.3418 13.1959C26.0718 16.0644 24.899 23.0253 24.3028 26.2382C24.0525 27.5976 23.5546 28.0534 23.0737 28.0979C22.0275 28.1948 21.2343 27.4017 20.2218 26.7331C18.6384 25.6863 17.744 25.035 16.2056 24.0137C14.4281 22.8334 15.5812 22.1846 16.5937 21.1245C16.8581 20.847 21.465 16.6251 21.5521 16.2421C21.5634 16.1942 21.5746 16.0157 21.4678 15.9216C21.3637 15.8272 21.209 15.8595 21.0965 15.885C20.9362 15.9213 18.4078 17.6072 13.5028 20.9425C12.7856 21.4397 12.1359 21.6821 11.5509 21.6693C10.9096 21.6554 9.67214 21.3031 8.75245 21.0021C7.62745 20.6327 6.73027 20.4374 6.80902 19.8102C6.84839 19.4836 7.29558 19.1495 8.14777 18.8079Z"
                fill="white"
            />
            <defs>
                <linearGradient id="paint0_linear_849_942" x1="1800" y1="0.859375" x2="1800" y2="3628.98" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#2AABEE" />
                    <stop offset="1" stopColor="#229ED9" />
                </linearGradient>
            </defs>
        </svg>
    );
}
